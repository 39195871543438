<template>
  <div>
    <Title :loading="loading">{{ $t("chatSidebar.help") }}</Title>
    <div class="chatWrapp">
      <div class="d-flex justify-center py-2">
        <v-btn
          color="primary"
          @click="startConversation"
          :loading="loading"
          :disabled="loading"
          depressed
          >{{ $t("chatSidebar.new") }}</v-btn
        >
      </div>
      <Message
        v-for="message in conversations"
        :key="message.id"
        :message="message"
        @open="openMsg"
      ></Message>
      <div v-if="load" v-intersect="getConversations" class="text-center mt-2">
        <v-progress-circular
          :width="3"
          :size="25"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>
<script>
import * as types from '@/store/modules/chat/chat-mutation-types'
import Message from '../Shared/Message.vue'
import Title from '../Shared/Title.vue'
import { startHelpDeskChat, markAsRead } from '@/signalr/chat'
export default {
  name: 'Help',
  components: { Message, Title },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    conversations () {
      return this.$store.getters['chat/helpMessages']
    },
    load () {
      return this.$store.getters['chat/loadHelp']
    }
  },
  async created () {
    this.$store.commit(`chat/${types.CLEAR_HELP}`)
    await this.getConversations()
  },
  methods: {
    async getConversations () {
      this.loading = true

      await this.$store.dispatch('chat/getHelpMessages')

      this.loading = false
    },
    openMsg (message) {
      const found = this.conversations.find((msg) => msg.conversationId === message.conversationId && msg.threadId === message.threadId)
      if (!found) return
      if (!found.readByCurrentUser) {
        found.readByCurrentUser = true
        markAsRead(found.threadId, found.messageId)
      }
      this.$store.dispatch('chat/openConversation', { conversationId: found.conversationId, threadId: found.threadId })
    },
    async startConversation () {
      await startHelpDeskChat()
    }
  }
}
</script>
